import React, { useRef, useState } from "react";
import menu_dropdown from "../assets/menu_dropdown.svg";
import ic_play from "../assets/ic_play.svg";
import "./VideoGallery.scss";

function VideoGallery({ videoGallery, locale = "de" }) {
  const [video, setVideo] = useState(false);
  const [videoName, setVideoName] = useState(false);
  const [category, setCategory] = useState(false);

  const categories = [];

  let firstCategory = category;
  let firstVideo = video;
  let firstVideoName = videoName;

  const gallery = useRef(null);

  Object.keys(videoGallery.videoCategoriesFetched["de"]).forEach(
    (categoryId) => {
      const cat = videoGallery.videoCategoriesFetched["de"][categoryId];

      if (firstCategory === false) {
        firstCategory = cat.title[locale];
      }

      const videos = [];

      Object.keys(cat.videosFetched["de"]).forEach((video) => {
        if (firstVideo === false) {
          firstVideo = cat.videosFetched["de"][video].htmlCode["de"];
          firstVideoName = cat.videosFetched["de"][video].titel["de"];
        }

        const active = cat.videosFetched["de"][video].titel["de"] === videoName;

        videos.push(
          <li
            onClick={() => {
              setVideo(cat.videosFetched["de"][video].htmlCode[locale]);
              setVideoName(cat.videosFetched["de"][video].titel["de"]);
              if (window.innerWidth < 500) {
                console.log("gallery clicked on mobile", gallery.current);
                gallery.current.scrollIntoView({
                  block: "start",
                  inline: "nearest",
                  behavior: "smooth",
                });
              }
            }}
            className={active ? "active" : ""}
            key={`video_${cat.videosFetched["de"][video].titel["de"]}`}
          >
            {cat.videosFetched["de"][video].titel["de"]}
            {!active ? <img src={ic_play} alt="Abspielen" /> : null}
          </li>
        );
      });

      categories.push(
        <div
          className={`List-Category ${
            cat.title[locale] === category ? "active" : ""
          }`}
          onClick={() => setCategory(cat.title[locale])}
          key={`videocategory_${cat.title[locale]}`}
        >
          <div
            className={`List-Category-Title ${
              cat.title[locale] === category ? "active" : ""
            }`}
          >
            <h4 className={"title"}>{cat.title[locale]}</h4>
            <span className="videoCount">{videos.length} Videos</span>
            <img src={menu_dropdown} alt="Ein-/Ausblenden" />
          </div>

          <ul>{videos}</ul>
        </div>
      );
    }
  );

  // console.log(firstCategory, firstVideo, firstVideoName, video);
  if (firstCategory !== false) {
    if (video === false) {
      setTimeout(() => {
        setCategory(firstCategory);
        setVideo(firstVideo);
        setVideoName(firstVideoName);
      }, 500);
    }
  }

  return (
    <div className="VideoGallery" ref={gallery}>
      <div className="VideoGallery-SplitView">
        <div className="Video">
          <h2>{videoGallery.title[locale]}</h2>
          <div
            className="Video-Embed"
            dangerouslySetInnerHTML={{ __html: video }}
          />
        </div>
        <div className="List">
          <h2>{locale === "de" ? "Übersicht" : "Overview"}</h2>
          {categories}
        </div>
      </div>
    </div>
  );
}

export default VideoGallery;
