import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Masonry from "react-masonry-css";
import Team from "../components/Team";
import "./Teams.scss";
import isset from "../helpers/isset";
import { filter } from "lodash";

const Teams = ({ entries, assets, locale, urlIDs }) => {
  const [searchText, setSearchText] = useState("");

  if (entries === false) {
    return "Loading";
  }

  let teams = [];

  for (const team in entries.teams) {
    teams.push(entries.teams[team]);
  }

  const data = entries.seiten[urlIDs.teams].fields;

  if (searchText.length > 0) {
    teams = filter(entries.teams, function (o) {
      if (
        isset(() => o.fields.name.de) &&
        o.fields.name.de.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
      ) {
        return true;
      }

      if (
        isset(() => o.fields.searchParams.de) &&
        o.fields.searchParams.de
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) >= 0
      ) {
        return true;
      }

      return false;
    });
  }

  const renderedTeams = [];

  teams.forEach((team) => {
    let image = false;

    if (isset(() => team.fields.foto["de"])) {
      image = assets[team.fields.foto["de"].sys.id];
    } else if (isset(() => team.fields.titelfoto["de"])) {
      image = assets[team.fields.titelfoto["de"].sys.id];
    }

    renderedTeams.push(
      <Team
        team={team.fields}
        image={image}
        key={`team_${team.sys.id}`}
        locale={locale}
      />
    );
  });

  const breakpointColumnsObj = {
    default: 3,
    1400: 3,
    900: 2,
    500: 1,
  };

  return (
    <div>
      {isset(() => data.seoTitle[locale]) ? (
        <Helmet>
          <title>{data.seoTitle[locale]}</title>
          <meta property="og:title" content={data.seoTitle[locale]} />
          <meta name="twitter:title" content={data.seoTitle[locale]} />

          <meta name="description" content={data.seoDescription[locale]} />
          <meta
            name="twitter:description"
            content={data.seoDescription[locale]}
          />

          <meta name="theme-color" content="#E0334C" />
        </Helmet>
      ) : null}
      <div className="Teams-Hero">
        <h1>{data.heroText[locale]}</h1>
        <input
          type="search"
          placeholder={locale === "de" ? "Stichwortsuche" : "Search"}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className={"Teams"}>
        <div className="Teams-Content">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {renderedTeams}
          </Masonry>
        </div>
      </div>
    </div>
  );
};

export default Teams;
