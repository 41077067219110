import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "./Advantages.scss";
import { urlIDs } from "../App";
import isset from "../helpers/isset";
import Advantage from "./Advantage";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function Advantages({ pageContents, assets, vorteileBySite, pageId, locale }) {
  const [page, setPage] = useState(pageId);

  const shownAdvantages = [];
  if (isset(() => vorteileBySite[page])) {
    for (const vorteilId in vorteileBySite[page]) {
      shownAdvantages.push(vorteileBySite[page][vorteilId]);
    }
  }

  const advantages = [];
  let advantageGroup = [];

  let advantagesPerColumn = 3;

  if (shownAdvantages.length <= 6) {
    advantagesPerColumn = 2;
  }
  if (shownAdvantages.length <= 3) {
    advantagesPerColumn = 1;
  }

  shownAdvantages.forEach((shownAdvantage) => {
    const image = assets[shownAdvantage.fields.icon.de.sys.id];
    advantageGroup.push(
      <CSSTransition
        key={`advantage_transition_${shownAdvantage.sys.id}`}
        addEndListener={(node, done) =>
          node.addEventListener("transitionend", done, false)
        }
        classNames="alert"
      >
        <Advantage
          key={`advantage_${shownAdvantage.sys.id}`}
          headline={shownAdvantage.fields.titel[locale]}
          text={shownAdvantage.fields.zusatztext[locale]}
          image={image}
        />
      </CSSTransition>
    );

    if (advantageGroup.length === advantagesPerColumn) {
      advantages.push([...advantageGroup]);
      advantageGroup = [];
    }
  });

  if (advantageGroup.length > 0) {
    advantages.push([...advantageGroup]);
  }

  let advantageFilter = null;
  if (pageId === urlIDs.home) {
    if (page === pageId) {
      setPage(urlIDs.workHQ);
    }
    // homepage, need to create tabs
    const filterOptions = [];

    const pages = {
      [urlIDs.workHQ]: locale === "de" ? "Zentrale Wels" : "Headquarters Wels",
      [urlIDs.workPartner]: locale === "de" ? "Händler" : "Partners",
      [urlIDs.workRent]: locale === "de" ? "Tourismus" : "Tourism",
      [urlIDs.workSchool]: locale === "de" ? "Lehre" : "Apprenticeship",
    };

    for (const subPageId in pages) {
      // check if subpages have advantages, if they do, add them to be filtered
      if (Object.keys(vorteileBySite[subPageId]).length) {
        // subpage has advantages
        filterOptions.push(
          <li
            key={`filter_${subPageId}`}
            onClick={() => setPage(subPageId)}
            className={subPageId === page ? "active" : ""}
          >
            {pages[subPageId]}
          </li>
        );
      }
    }

    advantageFilter = <ul className={"Advantages-Filter"}>{filterOptions}</ul>;
  }

  return (
    <div className="Advantages">
      <div className="Advantages-text">
        <div className="row">
          <div className="col">
            <h2 className={"headline"}>
              {locale === "de" ? "Deine Vorteile" : "Your Benefits"}
            </h2>
          </div>
          <div className="col right">
            <div>{advantageFilter}</div>
          </div>
        </div>
      </div>

      <Swiper
        spaceBetween={32}
        slidesPerView={2.5}
        className={"Advantages-Slider"}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        scrollbar={{ draggable: true }}
        navigation
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1.15,
            spaceBetween: 32,
          },
          // when window width is >= 480px
          760: {
            slidesPerView: 2.5,
            spaceBetween: 24,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2.5,
            spaceBetween: 32,
          },
        }}
      >
        {advantages.map((advantageGroup, index) => (
          <SwiperSlide key={`advantageGroup_${index}`}>
            <TransitionGroup className={"Advantages-Content"}>
              {advantageGroup}
            </TransitionGroup>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="Advantages-text">
        {locale === "de" ? (
          <span>
            Wir bemühen uns dir das bestmögliche Angebot an Vorteilen für deinen
            Arbeitsort zusammenzustellen. Bitte informiere dich bei deinem
            Bewerbungsgespräch welche individuellen Vorteile an deinem Standort
            gelten.
          </span>
        ) : (
          <span>
            We strive to offer you the best possible range of benefits for your
            to put together a place of work. Please check with your Job
            interview which individual benefits at your location apply.
          </span>
        )}
      </div>
    </div>
  );
}

export default Advantages;
