import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "./PhotoSlider.scss";
import isset from "../helpers/isset";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Image from "./Image";

function PhotoSlider({ photos }) {
  const locale = "de";

  const renderedPhotos = [];

  photos.forEach((photo) => {
    if (isset(() => photo.file[locale])) {
      renderedPhotos.push(
        <SwiperSlide>
          <Image
            src={photo.file[locale].url}
            width={window.innerWidth < 500 ? window.innerWidth : 1440}
            height={
              window.innerWidth < 500 ? Math.round(window.innerHeight / 2) : 700
            }
            alt={photo.file[locale].title}
            crop
          />
        </SwiperSlide>
      );
    }
  });

  return (
    <div className="PhotoSlider">
      <Swiper
        spaceBetween={32}
        slidesPerView={1}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
        className={"PhotoSlider-Slider"}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        navigation
        // pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
      >
        {renderedPhotos}
      </Swiper>
    </div>
  );
}

export default PhotoSlider;
