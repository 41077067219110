import React from "react";
import "./TextContent.scss";
import Image from "./Image";
import { Link } from "react-router-dom";

function TextContent({
  headline,
  text,
  textHtml = "",
  image,
  buttonVisible = false,
  buttonText = "",
  buttonLink = "",
  odd = false,
  shouldBeH1 = false,
}) {
  let img = null;

  if (image) {
    img = <Image src={image} width={740} height={740} alt={headline} crop />;
  }

  let contentText = <div dangerouslySetInnerHTML={{ __html: textHtml }} />;

  if (typeof text === "object" && textHtml.length === 0) {
    contentText = [];

    text.forEach((child) => {
      child.content.forEach((grandchild) => {
        contentText.push(<p>{grandchild.value}</p>);
      });
    });
  }

  let primaryButton = null;
  if (buttonVisible) {
    if (buttonLink.indexOf("/") === 0) {
      // is internal link
      primaryButton = (
        <Link className="button" to={buttonLink}>
          {buttonText}
        </Link>
      );
    } else {
      primaryButton = (
        <a
          href={buttonLink}
          className="button"
          target="_blank"
          rel="noreferrer"
        >
          {buttonText}
        </a>
      );
    }
  }

  return (
    <div
      className={`TextContent ${odd ? "odd" : "even"} ${
        img !== null ? "TextContent-WithImage" : "TextContent-WithoutImage"
      }`}
    >
      {img !== null ? <div className="TextContent-image">{img}</div> : null}

      <div className="TextContent-text">
        {shouldBeH1 ? <h1>{headline}</h1> : <h2>{headline}</h2>}
        <div className="Text-Content-text-content">{contentText}</div>
        {primaryButton}
      </div>
    </div>
  );
}

export default TextContent;
