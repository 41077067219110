import React from "react";
import "./Video.scss";

function Video({ video }) {
  const locale = "de";
  console.log(video);
  return (
    <div className="Video-Solo">
      <div
        className="Video-Embed"
        dangerouslySetInnerHTML={{ __html: video.fields.htmlCode[locale] }}
      />
    </div>
  );
}

export default Video;
