import React from "react";
import "./Facts.scss";

function Facts({
  value1 = "",
  text1 = "",
  value2 = "",
  text2 = "",
  value3 = "",
  text3 = "",
  value4 = "",
  text4 = "",
}) {
  if (value1.length === 0) {
    return null;
  }
  return (
    <div className="Facts">
      <div className="Facts-Content">
        <div className="Fact">
          <span className="Fact-number">{value1}</span>
          <span className="Fact-text">{text1}</span>
        </div>
        <div className="Fact">
          <span className="Fact-number">{value2}</span>
          <span className="Fact-text">{text2}</span>
        </div>
        <div className="Fact">
          <span className="Fact-number">{value3}</span>
          <span className="Fact-text">{text3}</span>
        </div>
        <div className="Fact">
          <span className="Fact-number">{value4}</span>
          <span className="Fact-text">{text4}</span>
        </div>
      </div>
    </div>
  );
}

export default Facts;
