import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y } from "swiper";
import "./ContentSlider.scss";
import isset from "../helpers/isset";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Image from "./Image";

function ContentSlider({ data, assets, contents, locale }) {
  const renderedPhotos = [];

  const slider = useRef(null);
  const [inView, setInView] = useState(false);

  // console.log("contents", contents);

  // console.log("data", data);

  const handleScroll = (event) => {
    const viewportOffset = slider.current.getBoundingClientRect();
    // console.log(viewportOffset);
    // console.log(viewportOffset.bottom);
    if (viewportOffset.top < 64 && viewportOffset.bottom > 200) {
      setInView(true);
    } else {
      setInView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  contents.forEach((content) => {
    if (isset(() => content.fields.foto["de"])) {
      const image = assets[content.fields.foto["de"].sys.id];

      // console.log("content", content);

      renderedPhotos.push(
        <SwiperSlide onScroll={handleScroll}>
          <div className="ContentValue">
            <div className="ContentValue-Text">
              <h4>{content.fields.subline[locale]}</h4>
              <h3>{content.fields.titel[locale]}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: content.fields.text_html[locale],
                }}
              />
            </div>
            <div className="ContentValue-Image">
              <Image
                src={image.file["de"].url}
                width={700}
                height={700}
                alt={image.file["de"].title}
                crop
              />
            </div>
          </div>
        </SwiperSlide>
      );
    }
  });

  return (
    <div
      className={`ContentSlider ${inView ? "inView" : "notInView"}`}
      ref={slider}
    >
      <Swiper
        spaceBetween={32}
        slidesPerView={1}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
        className={"ContentSlider-Slider"}
        modules={[Navigation, Pagination, A11y]}
        navigation
        loop
        pagination={{ clickable: true, type: "fraction" }}
        autoHeight={window.innerWidth < 500}
        // scrollbar={{ draggable: true }}
      >
        {renderedPhotos}
      </Swiper>
    </div>
  );
}

export default ContentSlider;
