import React from "react";
import "./PhotoContent.scss";
import Image from "./Image";

function PhotoContent({ headline, image }) {
  let img = <div />;

  img = <Image src={image} width={1280} alt={headline} />;

  return (
    <div className="PhotoContent">
      <div className="PhotoContent-text">
        <h2>{headline}</h2>
      </div>
      <div className="PhotoContent-image">{img}</div>
    </div>
  );
}

export default PhotoContent;
