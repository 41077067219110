import React from "react";
import "./Job.scss";
import { Link } from "react-router-dom";
import { returnIfString } from "../helpers/ReturnIfString";
import momentDe from "../helpers/momentDe";
import { getEmploymentTypes } from "../helpers/getEmploymentTypes";
import isset from "../helpers/isset";

function Job({ job, locale = "de" }) {
  const date = isset(() => job.mediumList.medium.positions.beginDate)
    ? momentDe(job.mediumList.medium.positions.beginDate)
    : momentDe(job.changeDate);

  let types = getEmploymentTypes(job.employmentLevels.employmentLevel, locale);

  let typeText = "";
  types.forEach((type) => {
    if (typeText.length) {
      typeText = typeText + ", ";
    } else {
      typeText = " • ";
    }
    typeText = typeText + type;
  });

  return (
    <div className="Job">
      <div className="Job-Text">
        <div className="row">
          <div className="col">
            <h4 className={"subtitle"}>
              {returnIfString(job.jobGroup.shortDescription.values[locale])}
              {typeText}
              {/* •{" "}*/}
              {/*{returnIfString(job.accountUserGroup.values[locale])*/}
              {/*  ? returnIfString(job.accountUserGroup.values[locale])*/}
              {/*  : "Standort fehlt"}*/}
            </h4>
          </div>
          <div className="col text-right">
            <span className="date">{date.format("LL")}</span>
          </div>
        </div>

        <h2>{returnIfString(job.description.values[locale])}</h2>
        <h3>{job.dccs_store}</h3>
      </div>

      <div className="buttons">
        <Link to={`/${locale}/jobs/${job.id}`} className={"button"}>
          {locale === "de" ? "Mehr Erfahren" : "Learn More"}
        </Link>
      </div>
    </div>
  );
}

export default Job;
