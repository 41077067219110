import React from "react";
import "./MemberSliderMember.scss";
import Image from "./Image";
import { Link } from "react-router-dom";

function MemberSliderMember({
  name,
  memberId,
  departmentName,
  departmentUrl,
  image,
}) {
  const locale = "de";

  let usedName = name.substring(0, name.indexOf(" "));
  if (usedName.length < 2) {
    usedName = name;
  }

  return (
    <Link
      className="MemberSliderMember"
      to={`/${locale}/teams/${departmentUrl}#${memberId}`}
    >
      <Image
        src={image.file[locale].url}
        width={250}
        height={400}
        alt={image.title[locale]}
        crop
        face
        visibleByDefault
        key={image.file[locale].fileName}
      />
      <div className="MemberSliderMember-text">
        <h4 className={"name"}>{usedName}</h4>
        <p className={"department"}>{departmentName}</p>
      </div>
    </Link>
  );
}

export default MemberSliderMember;
