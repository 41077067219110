import React from "react";
import { Helmet } from "react-helmet";
import Spacer from "../components/Spacer";
import { Link } from "react-router-dom";
import "./404.scss";

const Error404 = ({ locale = "de" }) => (
  <div>
    <Helmet>
      <title>404 - Diese Seite konnte nicht gefunden werden | Karriere</title>
      <meta name="description" content="Whoops." />
    </Helmet>
    <Spacer />

    {locale === "de" ? (
      <div className="Page404">
        <h1>Sorry, diese Seite konnte nicht gefunden werden.</h1>
        <p>
          Bitte versuch es doch noch einmal auf der{" "}
          <Link to={"/"}>Startseite</Link>
        </p>
      </div>
    ) : (
      <div className="Page404">
        <h1>Sorry, couldn't find this page.</h1>
        <p>
          Please try again from the <Link to={"/"}>start page</Link>
        </p>
      </div>
    )}
  </div>
);

export default Error404;
