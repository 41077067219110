import React from "react";
import "./Footer.scss";

function Footer({ footer, locale = "de" }) {
  console.log("footer fields", footer.fields);
  return (
    <footer className="Footer">
      <div className="Footer-Content">
        <div className="row">
          <div
            className="col"
            dangerouslySetInnerHTML={{
              __html: footer.fields.text_html[locale],
            }}
          />
          <div
            className="col social"
            dangerouslySetInnerHTML={{
              __html: footer.fields.textSocialMedia_html[locale],
            }}
          />
        </div>
        <hr />
        <div className="row">
          <div
            className="col legal"
            dangerouslySetInnerHTML={{
              __html: footer.fields.textUnten_html[locale],
            }}
          />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
