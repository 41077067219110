import React from "react";
import { filter, find } from "lodash";
import "./ContentRepeater.scss";
import TextContent from "./TextContent";
import Quote from "./Quote";
import Facts from "./Facts";
import isset from "../helpers/isset";
import TeamMember from "./TeamMember";
import Advantages from "./Advantages";
import MemberSlider from "./MemberSlider";
import PhotoContent from "./PhotoContent";
import PhotoSlider from "./PhotoSlider";
import ContentSlider from "./ContentSlider";
import VideoGallery from "./VideoGallery";
import Video from "./Video";
import Timeline from "./Timeline";

function ContentRepeater({
  contentIds = [],
  pageContents,
  assets,
  locale = "de",
  pageId = "",
  vorteileBySite,
  mitarbeiterBySite,
  firstContentIsH1 = false,
}) {
  const contents = [];
  let odd = true;

  contentIds.forEach((contentId, index) => {
    const data = pageContents[contentId.sys.id];
    let type = null;
    let shouldBeH1 = firstContentIsH1 ? contents.length === 0 : false;

    if (isset(() => data.sys.contentType)) {
      type = data.sys.contentType.sys.id;
    } else if (isset(() => data.sys.type)) {
      type = data.sys.type;
    }

    if (type === "textblock") {
      let image = null;
      odd = !odd;

      if (
        isset(() => data.fields?.foto[locale]?.sys?.id) &&
        data.fields?.foto[locale]?.sys?.id
      ) {
        image = assets[data.fields.foto[locale].sys.id].file[locale].url;
      } else if (
        isset(() => data.fields?.foto["de"]?.sys?.id) &&
        data.fields.foto["de"].sys.id
      ) {
        image = assets[data.fields.foto["de"].sys.id].file["de"].url;
      }

      contents.push(
        <TextContent
          key={`content_${data.sys.id}`}
          headline={data.fields.titel[locale]}
          text={data.fields.text[locale]}
          textHtml={data.fields.text_html[locale]}
          image={image}
          odd={odd}
          shouldBeH1={shouldBeH1}
          buttonLink={
            isset(() => data.fields.link[locale])
              ? data.fields.link[locale]
              : ""
          }
          buttonText={
            isset(() => data.fields.linkText[locale])
              ? data.fields.linkText[locale]
              : ""
          }
          buttonVisible={isset(() => data.fields.link[locale])}
        />
      );
    } else if (type === "photo") {
      let image = null;
      if (data.fields.photo["de"].sys.id) {
        image = assets[data.fields.photo["de"].sys.id].file["de"].url;
      }

      contents.push(
        <PhotoContent
          key={`content_${data.sys.id}`}
          headline={data.fields.headline[locale]}
          image={image}
        />
      );
    } else if (type === "photoslider") {
      const photos = [];

      if (isset(() => data.fields.photos[locale])) {
        data.fields.photos[locale].forEach((photo) => {
          if (isset(() => assets[photo.sys.id])) {
            photos.push(assets[photo.sys.id]);
          }
        });
      }

      contents.push(
        <PhotoSlider key={`content_${data.sys.id}`} photos={photos} />
      );
    } else if (type === "zitate") {
      const headline = isset(() => data.fields.titel[locale])
        ? data.fields.titel[locale]
        : false;
      const text = isset(() => data.fields.zitat[locale])
        ? data.fields.zitat[locale]
        : false;
      const buttonText = isset(() => data.fields.buttonText[locale])
        ? data.fields.buttonText[locale]
        : false;
      const buttonUrl = isset(() => data.fields.buttonUrl[locale])
        ? data.fields.buttonUrl[locale]
        : false;

      contents.push(
        <Quote
          key={`content_${data.sys.id}`}
          headline={headline}
          text={text}
          buttonText={buttonText}
          buttonUrl={buttonUrl}
        />
      );
    } else if (type === "facts") {
      contents.push(
        <Facts
          key={`content_${data.sys.id}`}
          value1={data.fields.wert1[locale]}
          text1={data.fields.text1[locale]}
          value2={data.fields.wert2[locale]}
          text2={data.fields.text2[locale]}
          value3={data.fields.wert3[locale]}
          text3={data.fields.text3[locale]}
          value4={data.fields.wert4[locale]}
          text4={data.fields.text4[locale]}
        />
      );
    } else if (type === "mitarbeiter") {
      // order answers accordingly to real content
      odd = !odd;
      let specificContentsOrdered = [];
      data.fields.answers[locale].forEach((answer) => {
        specificContentsOrdered.push(
          find(pageContents, (o) => {
            return answer.sys.id === o.sys.id;
          })
        );
      });

      contents.push(
        <TeamMember
          data={data}
          assets={assets}
          pageContents={specificContentsOrdered}
          key={`content_${data.sys.id}`}
          locale={locale}
          odd={odd}
        />
      );
    } else if (type === "contentslider") {
      // console.log(data);
      let specificContents = false;

      if (isset(() => data.fields.inhalte["de"])) {
        // console.log(pageContents);
        specificContents = filter(pageContents, (o) => {
          return find(data.fields.inhalte["de"], (p) => {
            return p.sys.id === o.sys.id;
          });
        });
      }

      contents.push(
        <ContentSlider
          data={data}
          assets={assets}
          contents={specificContents}
          key={`content_${data.sys.id}`}
          locale={locale}
        />
      );
    } else if (type === "videoGalerie") {
      contents.push(
        <VideoGallery
          videoGallery={data.fields}
          key={`content_${data.sys.id}`}
          locale={locale}
        />
      );
    } else if (type === "video") {
      const video = find(pageContents, (p) => p.sys.id === data.sys.id);
      contents.push(<Video video={video} />);
    } else if (type === "timelineGroup") {
      const timeline = find(pageContents, (p) => p.sys.id === data.sys.id);
      contents.push(<Timeline timeline={timeline} locale={locale} />);
    } else if (type === "prebuilt") {
      if (data.fields.inhalt.de === "Vorteile") {
        contents.push(
          <Advantages
            key={`content_${data.sys.id}`}
            assets={assets}
            pageContents={pageContents}
            vorteileBySite={vorteileBySite}
            pageId={pageId}
            locale={locale}
          />
        );
      } else if (data.fields.inhalt.de === "Mitarbeiterslider") {
        if (locale === "de") {
          contents.push(
            <MemberSlider
              key={`content_${data.sys.id}`}
              assets={assets}
              pageContents={pageContents}
              mitarbeiterBySite={mitarbeiterBySite}
              pageId={pageId}
              locale={locale}
            />
          );
        }
      }
    }
  });

  return <div className="ContentRepeater">{contents}</div>;
}

export default ContentRepeater;
