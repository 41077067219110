import React from "react";
import "./TeamMember.scss";
import Image from "./Image";
import isset from "../helpers/isset";
import { returnIfString } from "../helpers/ReturnIfString";
import { A11y, Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const Detail = ({ title, value }) => {
  return (
    <div className={"TeamMember-Detail"}>
      <span className="label">{title}</span>{" "}
      <span className="value">{value}</span>
    </div>
  );
};

function TeamMember({ data, assets, pageContents, odd }) {
  const locale = "de";

  const photos = [];

  if (isset(() => data.fields.photos.de)) {
    data.fields.photos.de.forEach((photo) => {
      const image = assets[photo.sys.id];

      photos.push(
        <SwiperSlide key={`memberslide_${photo.sys.id}`}>
          <Image
            src={image.file[locale].url}
            // width={548}
            // height={644}
            width={657}
            height={772}
            alt={image.title[locale]}
            crop
            face
          />
        </SwiperSlide>
      );
    });
  }

  const swiperModules = [A11y];

  if (photos.length > 1) {
    swiperModules.push(Navigation, Pagination, Autoplay);
  }

  return (
    <div className={`TeamMember ${odd ? "odd" : "even"}`} id={data.sys.id}>
      <div className="TeamMember-image">
        <Swiper
          spaceBetween={32}
          slidesPerView={1}
          className={"MemberSlider-Slider"}
          modules={swiperModules}
          navigation={photos.length > 1}
          loop
          autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
          pagination={photos.length > 1 ? { clickable: true } : false}
          // scrollbar={{ draggable: true }}
        >
          {photos}
        </Swiper>
      </div>
      <div className="TeamMember-text">
        <h2>{data.fields.name[locale]}</h2>

        <div className="TeamMember-Details">
          <div className="TeamMember-Details-Content">
            {isset(() => data.fields.rolle[locale]) ? (
              <Detail
                title={"Rolle"}
                value={returnIfString(data.fields.rolle[locale])}
              />
            ) : null}
            {isset(() => data.fields.location[locale]) ? (
              <Detail
                title={"Standort"}
                value={returnIfString(data.fields.location[locale])}
              />
            ) : null}
          </div>
        </div>

        {pageContents
          ? pageContents.map((pageContent) => (
              <div>
                {pageContent.fields.headline ? (
                  <h3>{pageContent.fields.headline[locale]}</h3>
                ) : null}

                <p>{pageContent.fields.text[locale]}</p>
              </div>
            ))
          : null}
      </div>
    </div>
  );
}

export default TeamMember;
