import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { filter, find } from "lodash";
import Hero from "../components/Hero";
import TextContent from "../components/TextContent";
import isset from "../helpers/isset";
import ContentRepeater from "../components/ContentRepeater";
import Job from "../components/Job";
import "./Team.scss";

const Team = ({ teams, jobs, assets, entries, locale }) => {
  const { id } = useParams();
  const [team, setTeam] = useState(false);

  if (teams === false || typeof teams === "undefined") {
    return "Loading";
  }

  if (team === false) {
    if (teams !== false) {
      setTeam(
        find(teams, (o) => {
          return o.fields.url["de"] === id;
        })
      );
    }
    return "Loading";
  } else {
    // console.log("team found");
  }

  console.log("team", team);

  // console.log(team, id);
  let hero = null;
  let firstContentIsH1 = true;
  const data = team.fields;
  let showHero = true;
  if (isset(() => data.showHero.de)) {
    showHero = data.showHero.de;
  }

  if (showHero) {
    if (data.titelfoto) {
      firstContentIsH1 = false;
      hero = (
        <Hero
          text={data.name[locale]}
          image={assets[data.titelfoto[locale].sys.id].file[locale].url}
        />
      );
    }
  }

  // const renderedJobs = [];
  const filteredJobs = filter(jobs, (o) =>
    data.stellenanzeigen.de.includes(o.id)
  );

  return (
    <div>
      {isset(() => data.seoTitle[locale]) ? (
        <Helmet>
          <title>{data.seoTitle[locale]}</title>
          <meta property="og:title" content={data.seoTitle[locale]} />
          <meta name="twitter:title" content={data.seoTitle[locale]} />

          <meta name="description" content={data.seoDescription[locale]} />
          <meta
            name="twitter:description"
            content={data.seoDescription[locale]}
          />

          <meta name="theme-color" content="#E0334C" />
        </Helmet>
      ) : null}

      {hero}
      <TextContent
        headline={firstContentIsH1 ? data.name[locale] : null}
        text={
          isset(() => data.beschreibung[locale].content)
            ? data.beschreibung[locale].content
            : ""
        }
        image={
          isset(() => assets[data.foto[locale].sys.id].file[locale].url)
            ? assets[data.foto[locale].sys.id].file[locale].url
            : false
        }
        odd
        shouldBeH1={firstContentIsH1}
      />
      {isset(() => data.teammitglieder[locale]) ? (
        <ContentRepeater
          contentIds={data.teammitglieder[locale]}
          pageContents={entries.pageContents}
          assets={assets}
          locale={locale}
          firstContentIsH1={firstContentIsH1}
        />
      ) : null}

      {data.stellenanzeigen.de.length ? (
        <div className="Team-Jobs">
          <h2>Offene Jobs</h2>
          <div className="Team-Jobs-List">
            {filteredJobs.map((job, index) => {
              return <Job job={job} key={`job_${job.id}`} />;
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Team;
