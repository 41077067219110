import React from "react";
import "./Hero.scss";
import Image from "./Image";
import { Link } from "react-router-dom";

function Hero({
  text,
  image,
  big = false,
  buttonVisible = false,
  buttonText = "",
  buttonLink = "",
  buttonSecondaryVisible = false,
  buttonSecondaryText = "",
  buttonSecondaryLink = "",
}) {
  let img = <div />;

  img = <Image src={image} width={1440} height={650} alt={text} crop />;

  let primaryButton = null;
  let secondaryButton = null;

  if (buttonVisible) {
    if (buttonLink.indexOf("/") === 0) {
      // is internal link
      primaryButton = (
        <Link className="button" to={buttonLink}>
          {buttonText}
        </Link>
      );
    } else {
      primaryButton = (
        <a
          href={buttonLink}
          className="button"
          target="_blank"
          rel="noreferrer"
        >
          {buttonText}
        </a>
      );
    }
  }

  if (buttonSecondaryVisible) {
    if (buttonSecondaryLink.indexOf("/") === 0) {
      // is internal link
      secondaryButton = (
        <Link className="button button-secondary" to={buttonSecondaryLink}>
          {buttonSecondaryText}
        </Link>
      );
    } else {
      secondaryButton = (
        <a
          href={buttonSecondaryLink}
          className="button button-secondary"
          target="_blank"
          rel="noreferrer"
        >
          {buttonSecondaryText}
        </a>
      );
    }
  }

  return (
    <div className={`Hero ${big ? "Hero-Big" : ""}`}>
      <div className="Hero-image">{img}</div>
      <div className="Hero-text">
        <h1>{text}</h1>
        <div className="Hero-buttons">
          {primaryButton}
          {secondaryButton}
        </div>
      </div>
    </div>
  );
}

export default Hero;
