import React from "react";
import "./Quote.scss";

function Quote({ headline, text, buttonText, buttonUrl }) {
  return (
    <div className="Quote">
      <div className="Quote-text">
        {headline ? <h3 className={"headline"}>{headline}</h3> : null}
        {text ? <h4 className={"text"}>{text}</h4> : null}
        {buttonText ? (
          <a href={buttonUrl} className="button button-secondary">
            {buttonText}
          </a>
        ) : null}
      </div>
    </div>
  );
}

export default Quote;
