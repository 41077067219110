import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "./MemberSlider.scss";
import isset from "../helpers/isset";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import MemberSliderMember from "./MemberSliderMember";
import { Link } from "react-router-dom";

function MemberSlider({ assets, mitarbeiterBySite, pageId }) {
  const locale = "de";

  const shownMemberSlider = [];
  if (isset(() => mitarbeiterBySite[locale])) {
    for (const memberId in mitarbeiterBySite[locale]) {
      shownMemberSlider.push(mitarbeiterBySite[locale][memberId]);
    }
  }

  const members = [];

  shownMemberSlider.forEach((member) => {
    if (isset(() => member.mitarbeiter.fields.photos)) {
      const image = assets[member.mitarbeiter.fields.photos.de[0].sys.id];
      members.push(
        <SwiperSlide key={`memberslide_${member.mitarbeiter.sys.id}`}>
          <MemberSliderMember
            key={`memberslidemember_${member.mitarbeiter.sys.id}`}
            name={member.mitarbeiter.fields.name[locale]}
            memberId={member.mitarbeiter.sys.id}
            departmentName={member.teamName}
            departmentUrl={member.teamUrl}
            image={image}
          />
        </SwiperSlide>
      );
    }
  });

  return (
    <div className="MemberSlider">
      <div className="MemberSlider-text">
        <div className="row">
          <div className="col">
            <h2 className={"headline"}>Lerne uns kennen</h2>
          </div>
          <div className="col right">
            <div>
              <Link to={`/${locale}/teams`}>Alle Teams kennenlernen</Link>
            </div>
          </div>
        </div>
      </div>

      <Swiper
        spaceBetween={32}
        slidesPerView={4.5}
        className={"MemberSlider-Slider"}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        navigation
        loop
        // pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1.15,
            spaceBetween: 32,
          },
          // when window width is >= 480px
          760: {
            slidesPerView: 3.5,
            spaceBetween: 24,
          },
          // when window width is >= 640px
          1280: {
            slidesPerView: 4.5,
            spaceBetween: 32,
          },
        }}
      >
        {members}
      </Swiper>
    </div>
  );
}

export default MemberSlider;
