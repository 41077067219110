import isset from "./isset";
import { isArray } from "lodash";

export function getEmploymentTypes(employmentLevel, locale = "de") {
  let types = [];

  // single types
  if (isset(() => employmentLevel.values[locale])) {
    types.push(employmentLevel.values[locale]);
  }

  // multiple types
  if (isArray(employmentLevel)) {
    employmentLevel.forEach((level) => {
      types.push(level.values[locale]);
    });
  }

  return types;
}
