import React from "react";
import "./Advantage.scss";
import Image from "./Image";

function Advantage({ headline, text, image }) {
  const locale = "de";

  return (
    <div className="Advantage">
      <div className="Advantage-icon">
        <Image
          src={image.file[locale].url}
          width={48}
          alt={image.title[locale]}
          crop
          key={image.file[locale].fileName}
          pureEmbed={image.file[locale].fileName.indexOf(".svg")}
        />
      </div>
      <div className="Advantage-text">
        <h4 className={"headline"}>{headline}</h4>
        <p className={"text"}>{text}</p>
      </div>
    </div>
  );
}

export default Advantage;
