import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { getBaseUrl } from "./helpers/getBaseUrl";

/*
TODO: Video für Header andenken
TODO: Serverinfrastruktur
TODO: Infoniqa Filter
TODO: Infoniqa Schnittstellen
TODO: Inhaltsslider
TODO: Videoeinbindungen
TODO: Zeitleiste
TODO: Responsive Optimierungen
TODO: SEO Texte
TODO: Cookie + GTM
TODO: API zu News
    * Zugang fehlt
TODO: Multilang
 */

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={getBaseUrl(false, "", true)}>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
