import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import "./Header.scss";

import logo from "../logo.svg";

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}

function Header({ urls, locale }) {
  const scrollDirection = useScrollDirection();
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = (event) => {
    setTimeout(() => setMenuOpen(false), 300);
  };

  if (urls === false) {
    return <div>Loading</div>;
  }

  // get current page and link to other language
  console.log(window.location.pathname);
  let currentLinkInOtherLanguage = locale === "de" ? "en" : "de";

  Object.keys(urls[locale]).forEach((key) => {
    if (window.location.pathname.includes(urls[locale][key].link)) {
      // current link!
      console.log("current link", key, window.location.pathname);

      const arrayOfParams = window.location.pathname.split("/");
      // console.log(arrayOfParams);

      currentLinkInOtherLanguage = `/${currentLinkInOtherLanguage}/${urls[currentLinkInOtherLanguage][key].link}`;

      if (key === "teams") {
        currentLinkInOtherLanguage = `${currentLinkInOtherLanguage}/${
          arrayOfParams[arrayOfParams.length - 1]
        }`;
      }
    }
  });

  if (window.location.pathname.includes("/jobs")) {
    console.log(window.location);
    if (locale === "de") {
      currentLinkInOtherLanguage = window.location.pathname.replace(
        "/de/",
        "/en/"
      );
    } else {
      currentLinkInOtherLanguage = window.location.pathname.replace(
        "/en/",
        "/de/"
      );
    }

    currentLinkInOtherLanguage = `${currentLinkInOtherLanguage}${window.location.hash}`;
  }

  const links = [
    <NavLink
      className={(navData) => (navData.isActive ? "active" : "")}
      to={`${locale}/${urls[locale].about.link}`}
      onClick={(e) => closeMenu(e)}
    >
      {urls[locale].about.linkName}
    </NavLink>,
    <span className={"MenuHover"}>
      <NavLink
        className={(navData) => (navData.isActive ? "active" : "")}
        to={`${locale}/${urls[locale].workIS.link}`}
        onClick={(e) => closeMenu(e)}
      >
        {urls[locale].workIS.linkName}
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.3225 5.01074L7 7.68241L9.6775 5.01074L10.5 5.83324L7 9.33324L3.5 5.83324L4.3225 5.01074Z"
            fill="#242B49"
            fillOpacity="0.6"
          />
        </svg>
      </NavLink>
      <ul>
        <li>
          <NavLink
            className={(navData) => (navData.isActive ? "active" : "")}
            to={`${locale}/${urls[locale].workHQ.link}`}
            onClick={(e) => closeMenu(e)}
          >
            {urls[locale].workHQ.linkName}
          </NavLink>
        </li>
        <li>
          <NavLink
            className={(navData) => (navData.isActive ? "active" : "")}
            to={`${locale}/${urls[locale].workPartner.link}`}
            onClick={(e) => closeMenu(e)}
          >
            {urls[locale].workPartner.linkName}
          </NavLink>
        </li>
        <li>
          <NavLink
            className={(navData) => (navData.isActive ? "active" : "")}
            to={`${locale}/${urls[locale].workRent.link}`}
            onClick={(e) => closeMenu(e)}
          >
            {urls[locale].workRent.linkName}
          </NavLink>
        </li>
        <li>
          <NavLink
            className={(navData) => (navData.isActive ? "active" : "")}
            to={`${locale}/${urls[locale].workSchool.link}`}
            onClick={(e) => closeMenu(e)}
          >
            {urls[locale].workSchool.linkName}
          </NavLink>
        </li>
      </ul>
    </span>,
    <NavLink
      className={(navData) => (navData.isActive ? "active" : "")}
      to={`${locale}/jobs`}
      onClick={(e) => closeMenu(e)}
    >
      Jobs
    </NavLink>,
    locale === "de" ? (
      <NavLink
        className={(navData) => (navData.isActive ? "active" : "")}
        to={`${locale}/${urls[locale].teams.link}`}
        onClick={(e) => closeMenu(e)}
      >
        {urls[locale].teams.linkName}
      </NavLink>
    ) : null,
    <NavLink
      className={(navData) => (navData.isActive ? "active" : "")}
      to={`${locale}/${urls[locale].academy.link}`}
      onClick={(e) => closeMenu(e)}
    >
      {urls[locale].academy.linkName}
    </NavLink>,
    <NavLink
      className={(navData) => (navData.isActive ? "c2a" : "c2a")}
      to={`${locale}/jobs`}
      onClick={(e) => closeMenu(e)}
    >
      {locale === "de" ? "Komm ins Team" : "Join the Team"}
    </NavLink>,
    <NavLink
      className={(navData) => (navData.isActive ? "" : "")}
      to={currentLinkInOtherLanguage}
      onClick={(e) => closeMenu(e)}
    >
      {locale === "de" ? "EN" : "DE"}
    </NavLink>,
  ];

  return (
    <header
      className={`Header ${scrollDirection === "down" ? "hide" : "show"}`}
    >
      <div className="content">
        <NavLink
          className={(navData) => (navData.isActive ? "active" : "")}
          to={`/${locale}/`}
        >
          <picture className={"logo"}>
            <img
              src={logo}
              alt="Logo von karriere.intersport.at"
              title="Logo von karriere.intersport.at"
              width={152}
            />
          </picture>
        </NavLink>

        <nav className={"desktop"}>
          <ul>
            {links.map((link, index) => (
              <li key={`link_desktop_${index}`}>{link}</li>
            ))}
          </ul>
        </nav>
        <nav className={"mobile"}>
          <Menu
            width={"90vw"}
            right
            isOpen={menuOpen}
            onOpen={() => setMenuOpen(true)}
            onClose={() => setMenuOpen(false)}
          >
            {links.map((link) => link)}
          </Menu>
        </nav>
      </div>
    </header>
  );
}

export default Header;
