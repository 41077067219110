import React, { useEffect, useState, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

import { getBaseUrl } from "./helpers/getBaseUrl";

import Home from "./pages/Home";
import About from "./pages/About";
import Error404 from "./pages/404";
import Job from "./pages/Job";
import Teams from "./pages/Teams";
import Team from "./pages/Team";
import Academy from "./pages/Academy";
import WorkHQ from "./pages/WorkHQ";
import WorkPartner from "./pages/WorkPartner";
import WorkRent from "./pages/WorkRent";
import WorkSchool from "./pages/WorkSchool";
import WorkIS from "./pages/WorkIS";
import Privacy from "./pages/Privacy";
// import * as url from "url";
const Jobs = lazy(() => import("./pages/Jobs"));

export const urlIDs = {
  home: "1W9PVGU2Kq1O34Dn1S3xoP",
  about: "3otiGfxUUQkV41MVUzvsYs",
  academy: "3Q74WivLAHNOgW4hZnaTbp",
  start: "1W9PVGU2Kq1O34Dn1S3xoP",
  workIS: "4O914ZCYmKTStKGSpoD5D6",
  workHQ: "60Ebn5g6kBR6KJuVIxvczz",
  workPartner: "4xiT5d0DZGcpXjjyi5uvWr",
  workRent: "4XR3mJ7wtLDZbAuEuCnfFq",
  workSchool: "riL3jpbGijp7fxxAeM3qg",
  privacy: "4s4iTkjVEqqhwikGHkbG03",
  teams: "2W0LkJtAgEUhMVtNnNWYTB",
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState(false);
  const [rentJobs, setRentJobs] = useState(false);
  const [entries, setEntries] = useState(false);
  const [assets, setAssets] = useState(false);

  const location = useLocation();

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    if (loading === true) {
      const url = getBaseUrl(true, "data", false, true);

      axios
        .get(url)
        .then((response) => {
          // handle success
          setJobs(response.data.jobs);
          setRentJobs(response.data.rentJobs);
          setEntries(response.data.entries);
          setAssets(response.data.assets);
        })
        .catch((error) => {
          // handle error
          console.log("my error message");
          console.error(error);
          console.log("end of my error message");
        });

      setLoading(false);
    }
  }, [loading]);

  const className = location.pathname
    .replace("/", " ")
    .replace("/", " ")
    .replace(".", "_");

  let urls = { de: {}, en: {} };

  if (entries) {
    urls = { de: {}, en: {} };
    for (let urlIDsKey in urlIDs) {
      urls.de[urlIDsKey] = { link: "", linkName: "" };
      urls.de[urlIDsKey].link = entries.seiten[urlIDs[urlIDsKey]].fields.url.de;
      urls.de[urlIDsKey].linkName =
        entries.seiten[urlIDs[urlIDsKey]].fields.linkName.de;
      urls.en[urlIDsKey] = { link: "", linkName: "" };
      urls.en[urlIDsKey].link = entries.seiten[urlIDs[urlIDsKey]].fields.url.en;
      urls.en[urlIDsKey].linkName =
        entries.seiten[urlIDs[urlIDsKey]].fields.linkName.en;
    }
  } else {
    for (let urlIDsKey in urlIDs) {
      urls.de[urlIDsKey] = { link: "", linkName: "" };
      urls.de[urlIDsKey].link = "";
      urls.de[urlIDsKey].linkName = "";
      urls.en[urlIDsKey] = { link: "", linkName: "" };
      urls.en[urlIDsKey].link = "";
      urls.en[urlIDsKey].linkName = "";
    }
  }

  // console.log("urls", urls);

  if (entries === false) {
    return <div>loading...</div>;
  }

  const localeDe = "de";
  const localeEn = "en";

  const activeLocale = window.location.pathname.startsWith("/en") ? "en" : "de";

  const footer = entries["footer"]["4P1tQSxEKphQbbGWjrfmWs"];
  // console.log("footer", footer);

  return (
    <div className={`App ${className}`}>
      <ScrollToTop />

      <Helmet>
        <title>Intersport Karriere</title>
        <meta name="theme-color" content="#E0334C" />
      </Helmet>

      <Header urls={urls} locale={activeLocale} />

      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={1000}>
          <Suspense fallback={<div className="suspenseFallback" />}>
            <Routes location={location}>
              <Route
                path={`/${localeDe}/`}
                element={
                  <Home
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeDe}
                  />
                }
              />

              <Route
                path={`${localeDe}/${urls.de.about.link}`}
                element={
                  <About
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeDe}
                  />
                }
              />

              <Route
                path={`${localeDe}/${urls.de.privacy.link}`}
                element={
                  <Privacy
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeDe}
                  />
                }
              />

              <Route
                path={`${localeDe}/${urls.de.academy.link}`}
                element={
                  <Academy
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeDe}
                  />
                }
              />

              <Route
                path={`${localeDe}/${urls.de.workIS.link}`}
                element={
                  <WorkIS
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeDe}
                  />
                }
              />

              <Route
                path={`${localeDe}/${urls.de.workHQ.link}`}
                element={
                  <WorkHQ
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeDe}
                  />
                }
              />

              <Route
                path={`${localeDe}/${urls.de.workPartner.link}`}
                element={
                  <WorkPartner
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeDe}
                  />
                }
              />

              <Route
                path={`${localeDe}/${urls.de.workRent.link}`}
                element={
                  <WorkRent
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeDe}
                  />
                }
              />

              <Route
                path={`${localeDe}/${urls.de.workSchool.link}`}
                element={
                  <WorkSchool
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeDe}
                  />
                }
              />

              <Route
                path={`${localeDe}/teams/:id`}
                element={
                  <Team
                    teams={entries.teams}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    entries={entries}
                    locale={localeDe}
                    jobs={jobs}
                  />
                }
              />
              <Route
                path={`${localeDe}/teams`}
                element={
                  <Teams
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeDe}
                  />
                }
              />

              <Route
                path={`${localeDe}/jobs/:id`}
                element={
                  <Job
                    jobs={jobs}
                    rentJobs={rentJobs}
                    locale={localeDe}
                    teams={entries.teams}
                    assets={assets}
                  />
                }
              />
              <Route
                path={`${localeDe}/jobs`}
                element={
                  <Jobs jobs={jobs} rentJobs={rentJobs} locale={localeDe} />
                }
              />

              <Route
                path="/rent"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=&position=&type=&rent=true" />
                }
              />

              <Route
                path="/rent/tirol"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Tirol&position=&type=&rent=true" />
                }
              />
              <Route
                path="/rent/vorarlberg"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Vorarlberg&position=&type=&rent=true" />
                }
              />
              <Route
                path="/rent/burgenland"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Burgenland&position=&type=&rent=true" />
                }
              />
              <Route
                path="/rent/kaernten"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Kärnten&position=&type=&rent=true" />
                }
              />
              <Route
                path="/rent/niederoesterreich"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Niederösterreich&position=&type=&rent=true" />
                }
              />
              <Route
                path="/rent/oberoesterreich"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Oberösterreich&position=&type=&rent=true" />
                }
              />
              <Route
                path="/rent/salzburg"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Salzburg&position=&type=&rent=true" />
                }
              />
              <Route
                path="/rent/steiermark"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Steiermark&position=&type=&rent=true" />
                }
              />
              <Route
                path="/rent/wien"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Wien&position=&type=&rent=true" />
                }
              />

              <Route
                path="/de/rent"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=&position=&type=&rent=true" />
                }
              />

              <Route
                path="/de/rent/tirol"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Tirol&position=&type=&rent=true" />
                }
              />
              <Route
                path="/de/rent/vorarlberg"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Vorarlberg&position=&type=&rent=true" />
                }
              />
              <Route
                path="/de/rent/burgenland"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Burgenland&position=&type=&rent=true" />
                }
              />
              <Route
                path="/de/rent/kaernten"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Kärnten&position=&type=&rent=true" />
                }
              />
              <Route
                path="/de/rent/niederoesterreich"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Niederösterreich&position=&type=&rent=true" />
                }
              />
              <Route
                path="/de/rent/oberoesterreich"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Oberösterreich&position=&type=&rent=true" />
                }
              />
              <Route
                path="/de/rent/salzburg"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Salzburg&position=&type=&rent=true" />
                }
              />
              <Route
                path="/de/rent/steiermark"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Steiermark&position=&type=&rent=true" />
                }
              />
              <Route
                path="/de/rent/wien"
                element={
                  <Navigate to="/de/jobs#searchText=&group=&location=&state=Wien&position=&type=&rent=true" />
                }
              />

              <Route
                path="/en/rent"
                element={
                  <Navigate to="/en/jobs#searchText=&group=&location=&state=&position=&type=&rent=true" />
                }
              />

              <Route
                path="/en/rent/tyrol"
                element={
                  <Navigate to="/en/jobs#searchText=&group=&location=&state=Tyrol&position=&type=&rent=true" />
                }
              />
              <Route
                path="/en/rent/vorarlberg"
                element={
                  <Navigate to="/en/jobs#searchText=&group=&location=&state=Vorarlberg&position=&type=&rent=true" />
                }
              />
              <Route
                path="/en/rent/burgenland"
                element={
                  <Navigate to="/en/jobs#searchText=&group=&location=&state=Burgenland&position=&type=&rent=true" />
                }
              />
              <Route
                path="/en/rent/carinthia"
                element={
                  <Navigate to="/en/jobs#searchText=&group=&location=&state=Carinthia&position=&type=&rent=true" />
                }
              />
              <Route
                path="/en/rent/loweraustria"
                element={
                  <Navigate to="/en/jobs#searchText=&group=&location=&state=Lower+Austria&position=&type=&rent=true" />
                }
              />
              <Route
                path="/en/rent/upperaustria"
                element={
                  <Navigate to="/en/jobs#searchText=&group=&location=&state=Upper+Austria&position=&type=&rent=true" />
                }
              />
              <Route
                path="/en/rent/salzburg"
                element={
                  <Navigate to="/en/jobs#searchText=&group=&location=&state=Salzburg&position=&type=&rent=true" />
                }
              />
              <Route
                path="/en/rent/styria"
                element={
                  <Navigate to="/en/jobs#searchText=&group=&location=&state=Styria&position=&type=&rent=true" />
                }
              />
              <Route
                path="/en/rent/vienna"
                element={
                  <Navigate to="/en/jobs#searchText=&group=&location=&state=Vienna&position=&type=&rent=true" />
                }
              />

              <Route path="/" element={<Navigate to="/de/" />} />

              <Route
                path={`/${localeEn}/`}
                element={
                  <Home
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeEn}
                  />
                }
              />

              <Route
                path={`${localeEn}/${urls.en.about.link}`}
                element={
                  <About
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeEn}
                  />
                }
              />

              <Route
                path={`${localeEn}/${urls.en.privacy.link}`}
                element={
                  <Privacy
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeEn}
                  />
                }
              />

              <Route
                path={`${localeEn}/${urls.en.academy.link}`}
                element={
                  <Academy
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeEn}
                  />
                }
              />

              <Route
                path={`${localeEn}/${urls.en.workIS.link}`}
                element={
                  <WorkIS
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeEn}
                  />
                }
              />

              <Route
                path={`${localeEn}/${urls.en.workHQ.link}`}
                element={
                  <WorkHQ
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeEn}
                  />
                }
              />

              <Route
                path={`${localeEn}/${urls.en.workPartner.link}`}
                element={
                  <WorkPartner
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeEn}
                  />
                }
              />

              <Route
                path={`${localeEn}/${urls.en.workRent.link}`}
                element={
                  <WorkRent
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeEn}
                  />
                }
              />

              <Route
                path={`${localeEn}/${urls.en.workSchool.link}`}
                element={
                  <WorkSchool
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeEn}
                  />
                }
              />

              <Route
                path={`${localeEn}/teams/:id`}
                element={
                  <Team
                    teams={entries.teams}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    entries={entries}
                    locale={localeEn}
                    jobs={jobs}
                  />
                }
              />
              <Route
                path={`${localeEn}/teams`}
                element={
                  <Teams
                    entries={entries}
                    assets={assets}
                    urls={urls}
                    urlIDs={urlIDs}
                    locale={localeEn}
                  />
                }
              />

              {/* JOBS*/}

              <Route
                path={`${localeEn}/jobs/:id`}
                element={
                  <Job
                    jobs={jobs}
                    rentJobs={rentJobs}
                    locale={localeEn}
                    teams={entries.teams}
                    assets={assets}
                  />
                }
              />
              <Route
                path={`${localeEn}/jobs`}
                element={
                  <Jobs jobs={jobs} rentJobs={rentJobs} locale={localeEn} />
                }
              />

              <Route path="/de/*" element={<Error404 />} />
              <Route path="/en/*" element={<Error404 locale={"en"} />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </Suspense>
        </CSSTransition>
      </TransitionGroup>
      {/*</LoadScriptOnlyIfNeeded>*/}
      <Footer footer={footer} urls={urls} locale={activeLocale} />
    </div>
  );
};

export default App;
