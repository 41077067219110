import React, { useState } from "react";
import "./Timeline.scss";
import TimelinePoint from "./TimelinePoint";
import { CSSTransition, TransitionGroup } from "react-transition-group";

function Timeline({ timeline, locale }) {
  const [activePoint, setActivePoint] = useState(0);

  const currentPoint =
    timeline.fields.zeitleistenpunkteConnected["de"][activePoint];
  const timelinePoints = [];

  timeline.fields.zeitleistenpunkteConnected["de"].forEach((point, index) => {
    timelinePoints.push(
      <TimelinePoint
        point={point}
        index={index}
        setActivePoint={(index) => setActivePoint(index)}
        locale={locale}
      />
    );
  });

  return (
    <div className="Timeline">
      <div className="Timeline-Detail">
        <TransitionGroup>
          <CSSTransition
            key={currentPoint.fields.titleShort[locale]}
            classNames="fadeTimeline"
            timeout={250}
          >
            <div className="Timeline-Detail-AnimationWrapper">
              <div className="Timeline-Detail-Text">
                <h2>{currentPoint.fields.titleShort[locale]}</h2>
                <p>{currentPoint.fields.description[locale]}</p>
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>

      <div className="Timeline-List">{timelinePoints}</div>
    </div>
  );
}

export default Timeline;
