export function getBaseUrl(
  full = true,
  subDir = "",
  nav = false,
  forceFull = false
) {
  const appendix = process.env.NODE_ENV === "development" ? "/" : "/";
  const serverUrl =
    process.env.NODE_ENV === "development" ||
    navigator.userAgent === "ReactSnap"
      ? "http://localhost:8080"
      : `https://${window.location.hostname}`;

  if (full) {
    if (navigator.userAgent === "ReactSnap" && forceFull === false) {
      return appendix + subDir;
    }
    return serverUrl + appendix + subDir;
  }
  if (nav) {
    return "/";
  }

  return appendix + subDir;
}
