import React from "react";
import "./Team.scss";
import Image from "./Image";
import { Link } from "react-router-dom";
import isset from "../helpers/isset";

function Team({ team, image, imageHeight = 480, locale = "de" }) {
  let img = <h1>Foto fehlt.</h1>;

  if (image && isset(() => image.file)) {
    img = (
      <Image
        src={image.file["de"].url}
        width={715}
        height={imageHeight}
        alt={image.title["de"]}
        crop
        face
        visibleByDefault
      />
    );
  }

  return (
    <Link className="Team" to={`/${locale}/teams/${team.url["de"]}`}>
      <div className="Team-image">{img}</div>
      <div className="Team-text">
        <h3>
          {isset(() => team.standort[locale])
            ? team.standort[locale]
            : "Standort fehlt"}{" "}
          •{" "}
          {isset(() => team.abteilung[locale])
            ? team.abteilung[locale]
            : "Abteilung fehlt"}
        </h3>
        <h2>
          {isset(() => team.name[locale]) ? team.name[locale] : "Name fehlt"}
        </h2>
        <p>
          {isset(() => team.kurzbeschreibung[locale])
            ? team.kurzbeschreibung[locale]
            : null}
        </p>
      </div>
    </Link>
  );
}

export default Team;
