import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { filter, find } from "lodash";
import QRCode from "react-qr-code";
import { returnIfString } from "../helpers/ReturnIfString";
import { LinkItUrl } from "react-linkify-it";

import "./Job.scss";
import createMarkup from "../helpers/createMarkup";
import isset from "../helpers/isset";
import { getEmploymentTypes } from "../helpers/getEmploymentTypes";
import Team from "../components/Team";
import { addLinks } from "react-link-text";

const Detail = ({ title, value }) => {
  return (
    <div className={"JobDetail-Detail"}>
      <span className="label">{title}</span>{" "}
      <span className="value">{value}</span>
    </div>
  );
};

const Job = ({ jobs, rentJobs, locale, teams, assets }) => {
  const { id } = useParams();
  const [job, setJob] = useState(false);

  if (jobs === false) {
    return "Loading";
  }

  if (job === false) {
    if (jobs !== false && rentJobs !== false) {
      let foundJob = find(jobs, (o) => o.id === id);

      if (!foundJob) {
        foundJob = find(rentJobs, (o) => o.id === id);
      }

      setJob(foundJob);
    }
    return "Loading";
  }

  const activityFields = [];

  if (isset(() => job.profil.activityFields.activityField)) {
    if (Array.isArray(job.profil.activityFields.activityField)) {
      job.profil.activityFields.activityField.forEach(
        (activityField, index) => {
          activityFields.push(
            <li key={`activityField_${index}`}>
              {addLinks(activityField.values[locale])}
            </li>
          );
        }
      );
    } else {
      if (isset(() => job.profil.activityFields.activityField.values[locale])) {
        activityFields.push(
          <li key={`activityField_0`}>
            {addLinks(job.profil.activityFields.activityField.values[locale])}
          </li>
        );
      }
    }
  }

  const managementActivities = [];

  if (isset(() => job.profil.managementActivities.managementActivity)) {
    // const managementActivitiesArray =
    //   typeof job.profil.managementActivities.managementActivity === "object"
    //     ? [job.profil.managementActivities.managementActivity]
    //     : job.profil.managementActivities.managementActivity;

    const managementActivitiesArray = Array.isArray(
      job.profil.managementActivities.managementActivity
    )
      ? job.profil.managementActivities.managementActivity
      : [job.profil.managementActivities.managementActivity];

    managementActivitiesArray.forEach((managementActivity, index) => {
      managementActivities.push(
        <li key={`managementActivity_${index}`}>
          {addLinks(managementActivity.values[locale])}
        </li>
      );
    });
  }

  const requirements = [];

  if (isset(() => job.profil.requirements.requirement)) {
    job.profil.requirements.requirement.forEach((requirement, index) => {
      requirements.push(
        <li key={`requirement_${index}`}>{requirement.values[locale]}</li>
      );
    });
  }

  const imageUrlBase = "https://intersport.infoniqa.co.at/engage";
  let image = "";
  if (job.entityLayout.headerArea.showImage === "true") {
    // true is a string, not a true bool.
    image = imageUrlBase + job.entityLayout.headerArea.imagePath;
    image = image.replace("&maxWidth=150", "&maxWidth=1440");
    image = image.replace("&maxHeight=150", "&maxHeight=800");
  }

  let salary = returnIfString(job.salaryWish.value);
  if (salary.length) {
    salary = parseFloat(salary);
    salary = salary.toLocaleString("de-AT");
  }

  let types = getEmploymentTypes(job.employmentLevels.employmentLevel, locale);
  let typeText = "";
  let typeTextPure = "";

  types.forEach((type) => {
    if (typeText.length) {
      typeText = typeText + ", ";
      typeTextPure = typeTextPure + ", ";
    } else {
      typeText = " • ";
    }
    typeText = typeText + type;
    typeTextPure = typeTextPure + type;
  });

  const filteredTeams = filter(teams, (o) => job.dccs_teams.includes(o.sys.id));
  const renderedTeams = [];

  filteredTeams.forEach((team) => {
    let image = false;

    if (isset(() => team.fields.foto[locale])) {
      image = assets[team.fields.foto[locale].sys.id];
    } else if (isset(() => team.fields.titelfoto[locale])) {
      image = assets[team.fields.titelfoto[locale].sys.id];
    }

    renderedTeams.push(
      <Team
        team={team.fields}
        image={image}
        key={`team_${team.sys.id}`}
        imageHeight={240}
      />
    );
  });

  let link =
    job.accountUserGroup.values.de === "Händlerinserat"
      ? `mailto:${
          job.exertionPlaceAddress.contact
        }?subject=Bewerbung für ${returnIfString(
          job.description.values[locale]
        )}`
      : job.link;

  let currencyUnit = returnIfString(job.salaryWish.currencyUnit);
  if (currencyUnit === "pm") {
    if (locale === "de") {
      currencyUnit = "pro Monat";
    } else {
      currencyUnit = "per Month";
    }
  }

  return (
    <div className="JobDetail">
      <Helmet>
        <title>{returnIfString(job.description.values[locale])}</title>
        <meta
          property="og:title"
          content={returnIfString(job.description.values[locale])}
        />
        <meta
          name="twitter:title"
          content={returnIfString(job.description.values[locale])}
        />

        <meta name="theme-color" content="#E0334C" />
      </Helmet>

      {image.length ? (
        <img
          src={image}
          alt={returnIfString(job.description.values[locale])}
          className={`heroImage`}
        />
      ) : null}

      <div className="JobDetail-Content">
        <div className="JobDetail-Text">
          {isset(() => job.additionalHeader.values[locale]) ? (
            <h3>{returnIfString(job.additionalHeader.values[locale])}</h3>
          ) : null}

          <h2 className={"sub"}>
            {returnIfString(job.profil.additionalHeader.values[locale])}
          </h2>
          <h1>{returnIfString(job.description.values[locale])}</h1>

          {activityFields.length ||
          returnIfString(
            job.profil.organizationalIntegration.values[locale]
          ) ? (
            <div className="avoid-break">
              <h2>
                {locale === "de" ? "Das erwartet dich" : "You can expect this"}
              </h2>
              {returnIfString(
                job.profil.organizationalIntegration.values[locale]
              ) ? (
                <LinkItUrl>
                  <p>
                    {job.profil.organizationalIntegration.values[locale]
                      .split("\n")
                      .map((item, key) => {
                        return (
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </p>
                </LinkItUrl>
              ) : null}
              {activityFields.length ? <ul>{activityFields}</ul> : null}
            </div>
          ) : null}

          {requirements.length ||
          returnIfString(job.profil.jobObjectives.values[locale]) ? (
            <div className="avoid-break">
              <h2>
                {locale === "de"
                  ? "Das bringst du mit"
                  : "You bring that with you"}
              </h2>
              {returnIfString(job.profil.jobObjectives.values[locale]) ? (
                <LinkItUrl>
                  <p>
                    {job.profil.jobObjectives.values[locale]
                      .split("\n")
                      .map((item, key) => {
                        return (
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </p>
                </LinkItUrl>
              ) : null}
              {requirements.length ? <ul>{requirements}</ul> : null}
            </div>
          ) : null}

          <div className="avoid-break">
            <h2>
              {locale === "de" ? "Das bieten wir dir" : "We offer you that"}
            </h2>
            {managementActivities.length ? (
              <ul>{managementActivities}</ul>
            ) : null}
            {job.profil.requirementDetail.values[locale] ? (
              <p
                dangerouslySetInnerHTML={createMarkup(
                  returnIfString(job.profil.requirementDetail.values[locale])
                )}
              />
            ) : null}
          </div>

          <div className="JobDetail-Teams">{renderedTeams}</div>
        </div>
        <div className="JobDetail-Details">
          <h2>Details</h2>
          <div className="JobDetail-Details-Content">
            <Detail
              title={locale === "de" ? "Titel" : "Title"}
              value={returnIfString(job.description.values[locale])}
            />

            <Detail
              title={locale === "de" ? "Bereich" : "Department"}
              value={returnIfString(
                job.jobGroup.shortDescription.values[locale]
              )}
            />

            {typeTextPure.length ? (
              <Detail
                title={locale === "de" ? "Arbeitszeit" : "Worktime"}
                value={typeTextPure}
              />
            ) : null}

            <Detail
              title={locale === "de" ? "Standort" : "Location"}
              value={job.dccs_store}
            />

            {job.accountUserGroup.values.de === "Händlerinserat" &&
            job.exertionPlaceAddress.name.length ? (
              <div className={"JobDetail-Detail"}>
                <span className="label">
                  {locale === "de" ? "Kontakt" : "Contact"}
                </span>{" "}
                <span className="value">
                  {job.exertionPlaceAddress.name3}
                  <br />
                  <a href={link}>{job.exertionPlaceAddress.contact}</a>
                  <br />
                  {job.exertionPlaceAddress.contactPhone.length ? (
                    <a href={`tel:${job.exertionPlaceAddress.contactPhone}`}>
                      {job.exertionPlaceAddress.contactPhone}
                    </a>
                  ) : null}
                  <br />
                  {job.exertionPlaceAddress.contactMobile.length ? (
                    <a href={`tel:${job.exertionPlaceAddress.contactMobile}`}>
                      {job.exertionPlaceAddress.contactMobile}
                    </a>
                  ) : null}
                </span>
              </div>
            ) : null}

            {isset(() => job.dccs_address) && job.dccs_address.length ? (
              <Detail
                title={locale === "de" ? "Adresse" : "Address"}
                value={job.dccs_address}
              />
            ) : null}

            {returnIfString(job.salaryWish.currency) ? (
              <Detail
                title={locale === "de" ? "Gehalt" : "Income"}
                value={`${salary} ${returnIfString(
                  job.salaryWish.currency
                )} ${currencyUnit}`}
              />
            ) : null}
            <div className="printOnly">
              Du willst den Job wieder auf unserer Webseite ansehen? Scanne
              einfach diesen QR Code mit deinem Smartphone!
              <div className="qr">
                <QRCode
                  value={window.location.href}
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
          </div>
          <div className={"buttons"}>
            <a href={link} target="_blank" className="button" rel="noreferrer">
              {locale === "de" ? "Jetzt bewerben" : "apply now"}
            </a>
            {job.userData.Rent ? (
              <a
                href={"/de/tourismus"}
                target="_blank"
                className="button button-secondary"
                rel="noreferrer"
              >
                {locale === "de"
                  ? "Mehr Infos zu Rent"
                  : "More Info about Rent"}
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Job;
