import React from "react";
import { Helmet } from "react-helmet";
import ContentRepeater from "../components/ContentRepeater";
import isset from "../helpers/isset";
import "./WorkIS.scss";
import { Link } from "react-router-dom";
import Image from "../components/Image";

const WorkIS = ({ entries, assets, urls, urlIDs, locale }) => {
  if (entries === false) {
    return "Loading";
  }

  const data = entries.seiten[urlIDs.workIS].fields;

  const dataHQ = entries.seiten[urlIDs.workHQ].fields;
  const dataPartner = entries.seiten[urlIDs.workPartner].fields;
  const dataRent = entries.seiten[urlIDs.workRent].fields;
  const dataSchool = entries.seiten[urlIDs.workSchool].fields;

  const buttonText = locale === "de" ? "Mehr erfahren" : "More Info";

  return (
    <div>
      {isset(() => data.seoTitle[locale]) ? (
        <Helmet>
          <title>{data.seoTitle[locale]}</title>
          <meta property="og:title" content={data.seoTitle[locale]} />
          <meta name="twitter:title" content={data.seoTitle[locale]} />

          <meta name="description" content={data.seoDescription[locale]} />
          <meta
            name="twitter:description"
            content={data.seoDescription[locale]}
          />

          <meta name="theme-color" content="#E0334C" />
        </Helmet>
      ) : null}

      <div className="WorkIS-Subpages">
        <Link
          to={`/${locale}/${urls[locale].workHQ.link}`}
          className={"WorkIS-Subpage"}
        >
          <Image
            src={assets[dataHQ.heroFoto["de"].sys.id].file["de"].url}
            width={1440}
            height={650}
            alt={dataHQ.heroText[locale]}
            crop
          />
          <div className="WorkIS-Subpage-Text">
            <div className="texts">
              <h2 className={"headline"}>{dataHQ.heroText[locale]}</h2>
              <p>{dataHQ.kurzbeschreibung[locale]}</p>
            </div>
            <span className="button">{buttonText}</span>
          </div>
        </Link>
        <Link
          to={`/${locale}/${urls[locale].workPartner.link}`}
          className={"WorkIS-Subpage"}
        >
          <Image
            src={assets[dataPartner.heroFoto["de"].sys.id].file["de"].url}
            width={1440}
            height={650}
            alt={dataPartner.heroText[locale]}
            crop
          />
          <div className="WorkIS-Subpage-Text">
            <div className="texts">
              <h2 className={"headline"}>{dataPartner.heroText[locale]}</h2>
              <p>{dataPartner.kurzbeschreibung[locale]}</p>
            </div>
            <span className="button">{buttonText}</span>
          </div>
        </Link>
        <Link
          to={`/${locale}/${urls[locale].workRent.link}`}
          className={"WorkIS-Subpage"}
        >
          <Image
            src={assets[dataRent.heroFoto["de"].sys.id].file["de"].url}
            width={1440}
            height={650}
            alt={dataRent.heroText[locale]}
            crop
          />
          <div className="WorkIS-Subpage-Text">
            <div className="texts">
              <h2 className={"headline"}>{dataRent.heroText[locale]}</h2>
              <p>{dataRent.kurzbeschreibung[locale]}</p>
            </div>
            <span className="button">{buttonText}</span>
          </div>
        </Link>
        <Link
          to={`/${locale}/${urls[locale].workSchool.link}`}
          className={"WorkIS-Subpage"}
        >
          <Image
            src={assets[dataSchool.heroFoto["de"].sys.id].file["de"].url}
            width={1440}
            height={650}
            alt={dataSchool.heroText[locale]}
            crop
          />
          <div className="WorkIS-Subpage-Text">
            <div className="texts">
              <h2 className={"headline"}>{dataSchool.heroText[locale]}</h2>
              <p>{dataSchool.kurzbeschreibung[locale]}</p>
            </div>
            <span className="button">{buttonText}</span>
          </div>
        </Link>
      </div>

      {isset(() => data.inhalte["de"]) ? (
        <ContentRepeater
          contentIds={data.inhalte["de"]}
          pageContents={entries.pageContents}
          assets={assets}
          pageId={urlIDs.workPartner}
          vorteileBySite={entries.vorteileBySite}
          mitarbeiterBySite={entries.mitarbeiterBySite}
          locale={locale}
        />
      ) : null}
    </div>
  );
};

export default WorkIS;
