import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (window.location.hash.length) {
      if (window.location.hash.indexOf("searchText") < 0) {
        setTimeout(() => {
          const yOffset = -100;
          const id = window.location.hash.replace("#", "");
          console.log("searching for id", id);
          const element = document.getElementById(id);
          console.log("found element", element);
          const y =
            element.getBoundingClientRect().top + window.scrollY + yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }, 500);
      }
    } else {
      window.scrollTo(0, 0);
    }

    setTimeout(() => {
      if (window.location.hash.indexOf("searchText")) {
        // search text params are apparent, but we only need them on the jobs page, remove if elsewhere
        if (!window.location.href.indexOf("/jobs")) {
          // not on jobs page, lets get rid of hashes
          window.location.hash = "";
        }
      }
    }, 500);
  }, [pathname]);

  return null;
}
