import React from "react";
import "./TimelinePoint.scss";

function TimelinePoint({ point, index, setActivePoint, locale }) {
  return (
    <div className="TimelinePoint" onClick={() => setActivePoint(index)}>
      <h3>{point.fields.titleShort[locale]}</h3>
      <h4>{point.fields.year["de"]}</h4>
      <div className="TimelinePoint-Description">
        {point.fields.description[locale]}
      </div>
    </div>
  );
}

export default TimelinePoint;
