import React from "react";
import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./Image.scss";

function limitNumberWithinRange(num, min, max) {
  const MIN = min || 1;
  const MAX = max || 20;
  const parsed = parseInt(num);
  return Math.min(Math.max(parsed, MIN), MAX);
}

const Image = ({
  src,
  height,
  width,
  className,
  alt = "",
  title = false,
  crop = false,
  visibleByDefault = false,
  fetchpriority = "auto",
  face = false,
  pureEmbed = false,
}) => {
  const baseUrl = "";

  // reducing width and height to a multiple of 8 to achieve less caching and resizing
  width = width - (width % 8);
  width = Math.round(limitNumberWithinRange(width, 16, 2048));

  if (height > 0) {
    height = height - (height % 8);
    height = Math.round(limitNumberWithinRange(height, 16, 2048));
  }

  const cropParam = !crop ? "" : "&fit=fill"; // crop by default
  const faceParam = face ? "&f=face" : ""; // crop to face

  const w = `&w=${width}`;
  const h = height > 0 ? `&h=${height}${cropParam}${faceParam}` : ``;

  const w15 = `&w=${Math.round(width * 1.5)}`;
  const h15 =
    height > 0 ? `&h=${Math.round(height * 1.5)}${cropParam}${faceParam}` : ``;

  const w2 = `&w=${Math.round(width * 2)}`;
  const h2 =
    height > 0 ? `&h=${Math.round(height * 2)}${cropParam}${faceParam}` : ``;

  const wSmall = `&w=${width}`;

  if (src === false) {
    return null;
  }

  if (src.indexOf("placeholder") > 0) {
    // image is a placeholder, let's avoid bad http calls
    return null;
  }

  const params = `${w}${h}`;
  const params15 = `${w15}${h15}`;
  const params2 = `${w2}${h2}`;

  const basicSrc = `${baseUrl}${src}?fm=jpg&fl=progressive${wSmall}`;

  if (pureEmbed) {
    return (
      <picture>
        <LazyLoadImage
          src={src}
          alt={alt}
          title={title ? title : alt}
          visibleByDefault={visibleByDefault}
          fetchpriority={fetchpriority}
        />
      </picture>
    );
  }

  return (
    <picture className={className}>
      <source
        srcSet={`${baseUrl}${src}?fm=webp${params}&q=80 1x, ${baseUrl}${src}?fm=webp${params15}&q=80 1.5x, ${baseUrl}${src}?fm=webp${params2}&q=75 2x`}
        type="image/webp"
      />
      <source
        srcSet={`${baseUrl}${src}?fm=jpg&fl=progressive${params}&q=80 1x, ${baseUrl}${src}?fm=jpg&fl=progressive${params15}&q=80 1.5x, ${baseUrl}${src}?fm=jpg&fl=progressive${params2}&q=75 2x`}
        type="image/jpeg"
      />

      <LazyLoadImage
        src={basicSrc}
        alt={alt}
        title={title ? title : alt}
        visibleByDefault={visibleByDefault}
        fetchpriority={fetchpriority}
      />
    </picture>
  );
};

Image.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  crop: PropTypes.bool,
};

Image.defaultProps = {
  className: "",
  width: 580,
  height: 0,
  crop: true,
};

export default Image;
