import React from "react";
import { Helmet } from "react-helmet";
import Hero from "../components/Hero";
import ContentRepeater from "../components/ContentRepeater";
import isset from "../helpers/isset";

const Home = ({ entries, assets, urls, urlIDs, locale }) => {
  if (entries === false) {
    return "Loading";
  }

  const data = entries.seiten[urlIDs.home].fields;

  return (
    <div>
      {isset(() => data.seoTitle[locale]) ? (
        <Helmet>
          <title>{data.seoTitle[locale]}</title>
          <meta property="og:title" content={data.seoTitle[locale]} />
          <meta name="twitter:title" content={data.seoTitle[locale]} />

          <meta name="description" content={data.seoDescription[locale]} />
          <meta
            name="twitter:description"
            content={data.seoDescription[locale]}
          />

          <meta name="theme-color" content="#E0334C" />
        </Helmet>
      ) : null}

      <Hero
        big
        text={data.heroText[locale]}
        image={assets[data.heroFoto["de"].sys.id].file["de"].url}
        buttonVisible={typeof data.heroButtonTextPrimary[locale] === "string"}
        buttonText={data.heroButtonTextPrimary[locale]}
        buttonLink={data.heroButtonUrlPrimary[locale]}
        buttonSecondaryVisible={
          typeof data.heroButtonTextSecondary[locale] === "string"
        }
        buttonSecondaryText={data.heroButtonTextSecondary[locale]}
        buttonSecondaryLink={data.heroButtonUrlSecondary[locale]}
      />
      {isset(() => data.inhalte["de"]) ? (
        <ContentRepeater
          contentIds={data.inhalte["de"]}
          pageContents={entries.pageContents}
          assets={assets}
          pageId={urlIDs.home}
          vorteileBySite={entries.vorteileBySite}
          mitarbeiterBySite={entries.mitarbeiterBySite}
          locale={locale}
        />
      ) : null}
    </div>
  );
};

export default Home;
